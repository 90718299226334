// http://www.zhangxinxu.com/jq/stylus/
// http://stylus.github.io/nib/

@import "nib"
@import "_variables"
@import "_util/mixin"
@import "_util/grid"

global-reset()

input, button
  margin: 0
  padding: 0
  &::-moz-focus-inner
    border: 0
    padding: 0

@font-face
  font-family: FontAwesome
  font-style: normal
  font-weight: normal
  src: url(font-icon-path + ".eot?v=#" + font-icon-version)
  src: url(font-icon-path + ".eot?#iefix&v=#" + font-icon-version) format("embedded-opentype"),
       url(font-icon-path + ".woff?v=#" + font-icon-version) format("woff"),
       url(font-icon-path + ".ttf?v=#" + font-icon-version) format("truetype"),
       url(font-icon-path + ".svg#fontawesomeregular?v=#" + font-icon-version) format("svg")

html, body, #container
  height: 100%

body
  background: color-background
  font: font-size font-sans
  -webkit-text-size-adjust: 100%

.outer
  clearfix()
  max-width: (column-width + gutter-width) * columns + gutter-width
  margin: 0 auto
  padding: 50px gutter-width 0

.inner
  column(columns)

.left, .alignleft
  float: left

.right, .alignright
  float: right

.clear
  clear: both

#container
  position: relative

.mobile-nav-on
  overflow: hidden

#wrap
  height: 100%
  width: 100%
  position: absolute
  top: 0
  left: 0
  transition: 0.2s ease-out
  z-index: 1
  background: color-background
  .mobile-nav-on &
    left: mobile-nav-width

if sidebar and sidebar isnt bottom
  #main
    @media mq-normal
      column(main-column)

if sidebar is left
  @media mq-normal
    #main
      float: right

@import "_extend"
@import "_partial/header"
@import "_partial/article"
@import "_partial/comment"
@import "_partial/archive"
@import "_partial/about"
@import "_partial/footer"
@import "_partial/highlight"
@import "_partial/mobile"

if sidebar
  @import "_partial/sidebar"

.toc-sticky
  position: sticky;
  top: 0;