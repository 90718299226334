if sidebar is bottom
  @import "sidebar-bottom"
else
  @import "sidebar-aside"

.widget
  @extend $base-style
  line-height: line-height
  word-wrap: break-word
  font-size: 0.9em
  ul, ol
    list-style: none
    margin: 0
    ul, ol
      margin: 0 20px
    ul
      list-style: disc
    ol
      list-style: circle

.category-list-count
.tag-list-count
.archive-list-count
  padding-left: 5px
  color: color-grey
  font-size: 0.85em
  &:before
    content: "("
  &:after
    content: ")"

.tagcloud
  a
    margin-right: 5px
    display: inline-block
