#sidebar
  @media mq-normal
    column(sidebar-column)

.widget-wrap
  margin: block-margin 0
  border-top: 12px solid color-grey

.widget-title
  @extend $block-caption
  padding 10px 0 0

.widget
  color: color-sidebar-text
  // text-shadow: 0 1px #fff
  background: color-widget-background
  // box-shadow: 0 -1px 4px color-widget-border inset
  // border: 1px solid color-widget-border
  padding: 15px
  // border-radius: 3px
  a
    color: color-link
    text-decoration: none
    &:hover
      // text-decoration: underline
  ul, ol, dl
    ul, ol, dl
      margin-left: 15px
      list-style: disc
  .name
    padding 5px 0
  .sns
    font-size 32px
    margin-top: 7px
    a
      // color: color-grey

// .tag-list

.tag-list-item
  display: inline-block

.tag-list-link
  display: inline-block
  border: 1px solid color-link
  padding: 3px 10px
  margin: 5px
